import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import 'gatsby-plugin-prismic-previews/dist/styles.css';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from './src/utils/i18next';
import './src/scss/global.scss';
import { PageContextProvider } from './src/utils/PageContext';

// Adds a shared React Context for Prismic preview sessions.
export const wrapRootElement = ({ element }) => (
  <I18nextProvider i18n={i18n}><PrismicPreviewProvider>{element}</PrismicPreviewProvider></I18nextProvider>
)

/**
 * Wrap all pages with a Translation provider and set the language on SSR time
 */
export const wrapPageElement = ({ element, props }) => {
  return <PageContextProvider {...props}>{element}</PageContextProvider>;
};